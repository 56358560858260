import $ from 'jquery';
import '../styles/index-header.css';
import './particles.min';
$(document).ready(function (e) {
    let menu_item = $('.main-menu ul li a.has-items');
    let sub_menu = $('.header-sub-menu');
    menu_item.on('mouseover', function (e) {
        let target_sub = $(this).data('target');
        $(target_sub).addClass('active');
    });
    menu_item.on('mouseout', function (e) {
        let target_sub = $(this).data('target');

        $(target_sub).removeClass('active');
    });
    sub_menu.on('mouseover', function (e) {
        let menu_item_id = $(this).attr('id');
        let _target = '\'#' + menu_item_id + "\'";
        let _menu_selector = 'a[data-target=' + _target + ']';
        $(_menu_selector).addClass('active');
    });
    sub_menu.on('mouseout', function (e) {
        let menu_item_id = $(this).attr('id');
        let _target = '\'#' + menu_item_id + "\'";
        let _menu_selector = 'a[data-target=' + _target + ']';
        $(_menu_selector).removeClass('active');
    });
    $(window).on('scroll', function (e) {
        let _scroll_top = $(this).scrollTop();
        if (_scroll_top > 80) {
            $('header.default').addClass('on-scroll');
        } else {
            $('header.default').removeClass('on-scroll');
        }
        if (_scroll_top > 150) {
            $('#scroll-top-btn').addClass('active');
        } else {
            $('#scroll-top-btn').removeClass('active');
        }
    });
    var container_selector = $('.container');
    var container_width = $('.container').width();
    var _container_edge_left = container_selector.offset().left;
    var _container_edge_right = $(window).width() - _container_edge_left - container_selector.width();
    $('.header-sub-menu').each(function (index, element) {
        var _target_link = 'a.' + $(element).attr('id');
        // _target_link = $(_target_link).closest('li');
        var first_item_width = $(element).find('ul li:first-child').width();
        var _pos = $(window).width() - ($(_target_link).offset().left + $(_target_link).width() / 2) - 48;
        var _pos_en = ($(_target_link).offset().left + $(_target_link).width() / 2) - 80 - _container_edge_left;
        var _sub_pos = _pos - _container_edge_right;
        var _sub_pos_en = _container_edge_left + _pos_en;
        var last_pos = ($(_target_link).offset().left + $(_target_link).width() / 2) - _container_edge_left - 81;
        var last_pos_en = ($(_target_link).offset().left + $(_target_link).width() / 2) - 81;
        if ($('html').attr('lang') == 'ar') {
            if (index == 4) {
                $(element).find('ul').css('left', last_pos);
            } else {
                $(element).find('ul').css('right', _sub_pos);
            }

        } else {
            if (index == 0) {
                $(element).find('ul').css('left', _pos_en - 7);
            } else {
                $(element).find('ul').css('left', _pos_en);
            }


        }


    });
    let _window_width = $(window).width();
    $('#search-btn-2').on('click', function (e) {
        e.preventDefault();
        $('#close-general-search').fadeIn();
        $('.search-body-wrapper').addClass('active');
        $('body').addClass('body-overflow');
    });


    $('#search-btn').on('click', function (e) {
        if(!$(this).closest('.form-7').hasClass('active')){
            e.preventDefault();
        }

        if (_window_width < 1300) {
            $('#close-general-search').fadeIn();
            $('.search-body-wrapper').addClass('active');
            $('body').addClass('body-overflow');
        } else {
            if (!$('.form-7').hasClass('active')) {

                $('.form-7').toggleClass('active');
            }
        }

    });
    $(document).mouseup(function (e) {
        var container = $(".form-7.active");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.removeClass('active');
            setTimeout(function () {
                $('#search-btn').fadeIn();
            }, 1000)
        }
    });
    $('#scroll-top-btn').on('click', function (e) {
        e.preventDefault();
        $("html, body").animate({scrollTop: 0}, "slow");

    });
    particlesJS.load('particles-js-2', '/web/json/particles.json', function () {
    });
    var color = $('.inner-menu,.page-inner-search').css("background-color");
    if (typeof color !== "undefined") {
            $('.page-2-bread').css({'background-color':color,'border-bottom':'1.5px solid rgba(255, 255, 255, 0.27059)'});
            $('.page-2-bread ul li a, .page-2-bread ul li').css('color',"#ffffff");
    }
    document.addEventListener('lazybeforeunveil', function(e){
        console.log('ss222222222');
        var bg = e.target.getAttribute('data-bg');
        if(bg){
            e.target.style.backgroundImage = 'url(' + bg + ')';
        }
    });
});
